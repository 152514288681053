import React, { useState, useEffect } from 'react'
import { NativeTextInput } from '@siftscience/focus-components/input'
import { Text } from '@siftscience/focus-components/text'
import { makeStyles } from '@material-ui/core/styles'
import moment from 'moment'
import { DataField } from './dtos'

const VALIDATION_ERROR_TEXT = 'Please enter the date in MM-DD-YYYY format'

const useStyles = makeStyles(() => ({
  dateWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '16px'
  },
  validationError: {
    maxWidth: '250px',
    color: '#EB3B2E'
  }
}))

interface DatePickerProps {
  value: string
  type?: 'date' | 'datetime-local'
  dataField: DataField
  onChange: (event, field: DataField) => void
  onBlur: (field: DataField) => void
  required: boolean
  autoFocus: boolean
  className: string
}

const getDateFormatForSave = (type: 'date' | 'datetime-local') =>
  type === 'datetime-local' ? '' : 'YYYY-MM-DD'

const getDateFormatForDisplay = (type: 'date' | 'datetime-local') =>
  type === 'datetime-local' ? 'YYYY-MM-DD HH:mm' : 'YYYY-MM-DD'

const DatePicker = ({
  value,
  type = 'date',
  dataField,
  onChange,
  onBlur,
  required,
  autoFocus,
  className
}: DatePickerProps): React.ReactElement => {
  const [isValidationError, setIsValidationError] = useState(false)
  const classes = useStyles()

  useEffect(() => {
    // reset validation error if user was able to change the date
    setIsValidationError(false)
  }, [value])

  const handleDatePaste = event => {
    event.preventDefault()
    const clipboardData = event.clipboardData
    const clipboardDataKind = clipboardData.items[0].kind

    if (clipboardDataKind === 'string') {
      setIsValidationError(false)
      const clipboardValue = event.clipboardData.getData('text/plain')
      const momentDate = moment(clipboardValue)
      const isDateValid = momentDate.isValid()

      if (isDateValid) {
        const dateFormat = getDateFormatForSave(type)
        const date = momentDate.format(dateFormat)

        const newEvent = { target: { value: date } }
        onChange(newEvent, dataField)
      } else {
        setIsValidationError(true)
      }
    }
  }

  const onInputBlur = () => {
    onBlur(dataField)
  }

  const onInputChange = event => {
    const value = event.target.value

    if (value) {
      const momentDate = moment(value)
      const dateFormat = getDateFormatForSave(type)
      const formattedValue = momentDate.format(dateFormat)
      const formattedEvent = {
        target: {
          value: formattedValue
        }
      }
      onChange(formattedEvent, dataField)
    } else {
      // reset if no value
      onChange(event, dataField)
    }
  }

  const formattedValue = value
    ? moment(value).format(getDateFormatForDisplay(type))
    : value

  return (
    <div className={classes.dateWrapper}>
      <NativeTextInput
        value={formattedValue as string}
        type={type}
        onChange={onInputChange}
        onBlur={onInputBlur}
        onPaste={handleDatePaste}
        required={required}
        autoFocus={autoFocus}
        className={className}
      />
      {isValidationError && (
        <Text size="small" className={classes.validationError}>
          {VALIDATION_ERROR_TEXT}
        </Text>
      )}
    </div>
  )
}

export default DatePicker
