import React, { createContext, useContext, useMemo, useState } from 'react'
import { ProcessorRequirementsDTO } from '../dtos'

interface IProcessorContext {
  processorRequirements?: ProcessorRequirementsDTO
  setProcessorRequirements: (requirements: ProcessorRequirementsDTO) => void
}

const ProcessorContext = createContext<IProcessorContext>({})

export const ProcessorProvider = ({ children }) => {
  const [processorRequirements, setProcessorRequirements] =
    useState<ProcessorRequirementsDTO>({})

  const contextValue = useMemo(
    () => ({
      processorRequirements,
      setProcessorRequirements
    }),
    [processorRequirements, setProcessorRequirements]
  )

  return (
    <ProcessorContext.Provider value={contextValue}>
      {children}
    </ProcessorContext.Provider>
  )
}

export const useProcessorContext = () => {
  return useContext(ProcessorContext)
}
