import React, { useEffect } from 'react'
import { IconButton } from '@siftscience/focus-components/button'
import { makeStyles } from '@material-ui/core/styles'
import {
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Tooltip
} from '@material-ui/core'

import {
  usePopupState,
  bindTrigger,
  bindMenu
} from 'material-ui-popup-state/hooks'

const HeaderMenu = ({ IconComponent, menuItems, popupId, tooltipTitle }) => {
  const classes = useStyles()
  const popupState = usePopupState({ variant: 'popover', popupId })

  useEffect(() => {
    const onClickOutside = event => {
      if (popupState.isOpen && !popupState.anchorEl?.contains(event.target)) {
        popupState.close()
      }
    }
    document.addEventListener('click', onClickOutside)
    return () => {
      document.removeEventListener('click', onClickOutside)
    }
  }, [popupState])

  return (
    <>
      <Tooltip title={tooltipTitle}>
        <IconButton
          {...bindTrigger(popupState)}
          variant="primary"
          className={popupState?.isOpen ? classes.openedPopup : ''}
        >
          <IconComponent color="#FFFFFF" className={classes.icon} />
        </IconButton>
      </Tooltip>

      <Menu
        {...bindMenu(popupState)}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        getContentAnchorEl={null}
      >
        {menuItems.map(({ href, text, icon }) => (
          <MenuItem component="a" href={href} key={href}>
            <ListItemIcon classes={{ root: classes.listItemIcon }}>
              {icon}
            </ListItemIcon>
            <ListItemText primary={text} />
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

const useStyles = makeStyles(theme => ({
  listItemIcon: { minWidth: 35 },
  icon: {
    height: '16px',
    maxWidth: '16px'
  },
  openedPopup: {
    '&.button': {
      backgroundColor: '#0A25A3'
    }
  }
}))

export default HeaderMenu
