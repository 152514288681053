import React from 'react'
import { Modal } from '@siftscience/focus-components/modal'
import { Action } from '@siftscience/focus-components/action-bar'
import { ButtonVariant } from '@siftscience/focus-components/button'

const MODAL_ACTION_LABEL = {
  confirm: 'Confirm',
  cancel: 'Cancel'
}

const MODAL_ACTIONS: Action[] = [
  { label: MODAL_ACTION_LABEL.confirm },
  {
    label: MODAL_ACTION_LABEL.cancel,
    variant: 'secondary' as ButtonVariant,
    lined: true
  }
]

export interface ConfirmationModalProps {
  isOpen?: boolean
  title?: string
  text?: React.ReactNode | string
  onConfirm?: () => void
  onCancel?: () => void
}

const ConfirmationModal = ({
  isOpen,
  title,
  text,
  onConfirm,
  onCancel
}: ConfirmationModalProps): React.ReactElement => {
  const onModalAction = (action: Action) => {
    if (action.label === MODAL_ACTION_LABEL.cancel && onCancel) {
      onCancel()
      return
    }

    if (action.label === MODAL_ACTION_LABEL.confirm && onConfirm) {
      onConfirm()
    }
  }

  return (
    <div className="det-modal">
      <Modal
        visible={!!isOpen}
        title={title}
        onCloseButtonClick={onCancel}
        onClickOutside={onCancel}
        actions={MODAL_ACTIONS}
        onAction={onModalAction}
        variant="fixed"
      >
        {text}
      </Modal>
    </div>
  )
}

export default ConfirmationModal
