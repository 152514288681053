import { useQuery } from 'react-query'
import axios from '../../utils/http/axios-local'
import { DatasetDTO, DisputeDTO, RenderingDTO } from './dtos'
import { useEffect } from 'react'

export const useDispute = (disputeId: string) => {
  const {
    data: disputeData,
    isLoading: isDisputeLoading,
    refetch: refetchDispute
  } = useQuery(
    `dispute-${disputeId}`,
    () => axios.get<DisputeDTO>(`/det/disputes/${disputeId}`),
    {
      refetchOnWindowFocus: false,
      enabled: !!disputeId
    }
  )

  return {
    disputeData: (disputeData?.data ?? null) as DisputeDTO,
    isDisputeLoading,
    refetchDispute
  }
}

export const useRenderings = (disputeId: string) => {
  const {
    data: renderingsData,
    isLoading: isRenderingsLoading,
    refetch: refetchRenderings
  } = useQuery(
    `renderings-${disputeId}`,
    () => axios.get<RenderingDTO[]>(`/det/renderings?dispute_id=${disputeId}`),
    {
      refetchOnWindowFocus: false,
      enabled: !!disputeId
    }
  )

  return {
    renderingsData: (renderingsData?.data ?? {}) as RenderingDTO[],
    isRenderingsLoading,
    refetchRenderings
  }
}

export const useDataset = (disputeId: string) => {
  const {
    data: datasetData,
    isLoading: isDatasetLoading,
    refetch: refetchDataset
  } = useQuery(
    `dataset-${disputeId}`,
    () => axios.get<{ dataset: DatasetDTO }>(`/det/datasets/${disputeId}`),
    {
      refetchOnWindowFocus: false,
      enabled: !!disputeId
    }
  )

  return {
    datasetData: (datasetData?.data ?? {}) as DatasetDTO,
    isDatasetLoading,
    refetchDataset
  }
}

export const useDetData = (
  disputeId: string,
  manuallySubmittedStatus: boolean
) => {
  const { disputeData, isDisputeLoading, refetchDispute } =
    useDispute(disputeId)
  const { renderingsData, isRenderingsLoading, refetchRenderings } =
    useRenderings(disputeId)
  const { datasetData, isDatasetLoading, refetchDataset } =
    useDataset(disputeId)

  const isLoading = isDisputeLoading || isRenderingsLoading || isDatasetLoading

  useEffect(() => {
    refetchDispute()
    refetchRenderings()
    refetchDataset()
  }, [manuallySubmittedStatus])

  return {
    isDETLoading: isLoading,
    dispute: disputeData,
    renderings: renderingsData ?? [],
    dataset: datasetData.dataset
  }
}
