import React, { useState } from 'react'
import { Modal } from '@siftscience/focus-components/modal'
import { Action } from '@siftscience/focus-components/action-bar'
import { Text } from '@siftscience/focus-components/text'
import { ButtonVariant } from '@siftscience/focus-components/button'
import { Tooltip } from '@siftscience/focus-components/tooltip'
import { makeStyles } from '@material-ui/core/styles'
import { VerificationWarningFilled } from '@siftscience/focus-components/icons/VerificationWarningFilled'
import { Send } from '@siftscience/focus-components/icons/Send'
import FinalizePreview from './finalize-preview'
import { useDisputeContext } from './context/dispute-context'
import axios from '../../utils/http/axios-local'
import { DisputeDTO, LifecycleStatusActions, LifecycleAction } from './dtos'

const MODAL_ACTION_LABEL = {
  send: 'Send',
  markReady: 'Mark Ready',
  cancel: 'Cancel'
}

const getSendTooltipText = () =>
  'Locks this dispute and submits the response automatically via an integration.'
const getMarkReadyTooltipText = (isManualDelivery: boolean) =>
  isManualDelivery
    ? 'Locks this dispute and finalizes the response in preparation for a manual delivery.'
    : 'Not recommended. Use this option if you plan to manually submit the response. This operation will lock the dispute.'

const getModalActionsMap = (
  isManualDelivery: boolean,
  classes
): Record<string, Action> => {
  return {
    send: {
      label: (
        <Tooltip content={getSendTooltipText()} className={classes.tooltip}>
          <div>
            <Send className={classes.svg} />
            <span>{MODAL_ACTION_LABEL.send}</span>
          </div>
        </Tooltip>
      ),
      size: 'medium',
      id: MODAL_ACTION_LABEL.send
    },
    markReady: {
      label: (
        <Tooltip
          content={getMarkReadyTooltipText(isManualDelivery)}
          className={classes.tooltip}
        >
          <Text>{MODAL_ACTION_LABEL.markReady}</Text>
        </Tooltip>
      ),
      variant: 'secondary' as ButtonVariant,
      size: 'medium',
      id: MODAL_ACTION_LABEL.markReady
    },
    cancel: {
      label: MODAL_ACTION_LABEL.cancel,
      variant: 'secondary' as ButtonVariant,
      size: 'medium',
      lined: true,
      id: MODAL_ACTION_LABEL.cancel
    }
  }
}

const useStyles = makeStyles(() => ({
  modalContent: {
    overflow: 'scroll'
  },
  modalFooterContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '8px'
  },
  tooltip: {
    zIndex: 3000
  },
  svg: {
    flex: '0 0 auto',
    verticalAlign: 'middle',
    width: '16px',
    height: '16px',
    marginRight: '8px'
  }
}))

const MODAL_FOOTER_CONTENT = () => {
  const classes = useStyles()

  return (
    <div className={classes.modalFooterContent}>
      <VerificationWarningFilled color="#EB3B2E" />
      <Text size="small">
        You won’t be able to modify response once it’s sent
      </Text>
    </div>
  )
}

interface FinalizeModalProps {
  onCloseFinalizeModal: () => void
  isManualDelivery: boolean
  lifecycleStatusActions: LifecycleStatusActions[]
}

const FinalizeModal = ({
  onCloseFinalizeModal,
  isManualDelivery,
  lifecycleStatusActions
}: FinalizeModalProps): React.ReactElement => {
  const { disputeId, setDispute } = useDisputeContext()
  const [isLoading, setIsLoading] = useState(false)
  const classes = useStyles()

  const actionsMap = getModalActionsMap(isManualDelivery, classes)

  const onCloseModal = () => {
    onCloseFinalizeModal()
  }

  const modalActions = (): Action[] => {
    const actions: Action[] = []

    if (
      lifecycleStatusActions.find(
        action => action.name === LifecycleAction.queue_to_send
      )
    ) {
      actions.push(actionsMap.send)
    }

    if (
      lifecycleStatusActions.find(
        action => action.name === LifecycleAction.ready_to_send
      )
    ) {
      actions.push(actionsMap.markReady)
    }

    actions.push(actionsMap.cancel)

    return actions
  }

  const onModalAction = (action: Action) => {
    if (action.id === MODAL_ACTION_LABEL.cancel) {
      onCloseModal()
      return
    }
    if (action.id === MODAL_ACTION_LABEL.send) {
      setIsLoading(true)
      axios
        .post<DisputeDTO>(`/det/disputes/${disputeId}/lifecycle/queue_to_send`)
        .then(({ data }) => {
          setIsLoading(false)
          setDispute(data)
          onCloseModal()
        })
        .catch(error => {
          console.log('Error while finalizing response', error)
          setIsLoading(false)
        })
    }

    if (action.id === MODAL_ACTION_LABEL.markReady) {
      setIsLoading(true)
      axios
        .post<DisputeDTO>(`/det/disputes/${disputeId}/lifecycle/ready_to_send`)
        .then(({ data }) => {
          setIsLoading(false)
          setDispute(data)
          onCloseModal()
        })
        .catch(error => {
          console.log('Error while finalizing response', error)
          setIsLoading(false)
        })
    }
  }

  return (
    <div className="det-modal">
      <Modal
        contentContainerClassName={classes.modalContent}
        variant="fixed"
        title={'Send Response'}
        onCloseButtonClick={onCloseModal}
        onClickOutside={onCloseModal}
        actions={modalActions()}
        onAction={onModalAction}
        footerContent={<MODAL_FOOTER_CONTENT />}
        size="large"
      >
        <FinalizePreview isParentLoading={isLoading} />
      </Modal>
    </div>
  )
}

export default FinalizeModal
