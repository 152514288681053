import React from 'react'
import { Title } from '@siftscience/focus-components/text'
import { Account } from '@siftscience/focus-components/icons'
import { makeStyles } from '@material-ui/core/styles'
import { Tooltip } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useCurrentUser } from '../../contexts/CurrentUserContext'

const UserInfo = () => {
  const classes = useStyles()
  const { currentUser } = useCurrentUser()

  return (
    <div className={classes.root}>
      {currentUser.masqueradeUser ? (
        <>
          <FontAwesomeIcon
            icon="mask"
            size="lg"
            className={classes.masqueradeIcon}
          />
          <Tooltip title={currentUser.name}>
            <div>
              {currentUser.masqueradeUser.client_name || currentUser.name}
            </div>
          </Tooltip>
        </>
      ) : (
        <>
          <Account className={classes.icon} color="#FFFFFF" />
          <Title size="small">{currentUser.name}</Title>
        </>
      )}
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center'
  },
  masqueradeIcon: {
    paddingRight: 5,
    color: 'red'
  },
  icon: {
    marginRight: '4px',
    width: '16px',
    height: '16px'
  }
}))

export default UserInfo
