import React, { useState, useEffect } from 'react'
import { SegmentedControl } from '@siftscience/focus-components/segmented-control'
import { Title } from '@siftscience/focus-components/text'
import { makeStyles } from '@material-ui/core/styles'

const NAVIGATION_ITEMS = [
  {
    value: 'disputes',
    path: '/ui/disputes',
    label: 'Disputes'
  },
  {
    value: 'inquiries',
    path: '/inquiries',
    label: 'Inquiries'
  },
  {
    value: 'alerts',
    path: '/ui/alerts',
    label: 'Alerts'
  },
  {
    value: 'transactions',
    path: '/ui/transactions',
    label: 'Transactions'
  },
  {
    value: 'invoices',
    path: '/ui/invoices',
    label: 'Invoices'
  },
  {
    value: 'reports',
    path: '/ui/reports',
    label: 'Reports'
  }
]

const MainNav = ({ MenuLogo }) => {
  const classes = useStyles()
  const currentLocationIndex = NAVIGATION_ITEMS.findIndex(tab =>
    window.location.pathname.includes(tab.value)
  )
  const [currentTabIndex, setCurrentTabIndex] = useState(currentLocationIndex)

  useEffect(
    () => setCurrentTabIndex(currentLocationIndex),
    [currentLocationIndex]
  )

  const navigationItemRenderer = (item, index, selected) => {
    const className = `${classes.navigationItem} ${
      selected ? classes.navigationItemSelected : ''
    }`
    return (
      <a href={item.path} className={className}>
        <Title size="xsmall">{item.label}</Title>
      </a>
    )
  }

  return (
    <>
      <MenuLogo />
      <SegmentedControl
        items={NAVIGATION_ITEMS}
        itemRenderer={navigationItemRenderer}
        selectedIndex={currentTabIndex}
        className={classes.navigation}
      />
    </>
  )
}

const useStyles = makeStyles(theme => ({
  navigation: {
    width: '518px',
    marginLeft: '26px',
    backgroundColor: '#295DED'
  },
  navigationItem: {
    color: '#FFFFFF',
    padding: '8px 16px',
    borderRadius: '6px',
    marginRight: '4px',
    '&:hover': {
      backgroundColor: '#1D47CB'
    },
    '&:last-child': {
      marginRight: 0
    }
  },
  navigationItemSelected: {
    backgroundColor: '#0A25A3'
  }
}))

export default MainNav
