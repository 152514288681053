import React from 'react'
import { DisputeProvider } from './dispute-context'
import { RenderingsProvider } from './renderings-context'
import { DatasetProvider } from './dataset-context'
import { SavingProvider } from './saving-context'
import { ProcessorProvider } from './processor-context'

// Combines all DET-related providers
export const DETProvider = ({ children }) => (
  <DisputeProvider>
    <ProcessorProvider>
      <RenderingsProvider>
        <DatasetProvider>
          <SavingProvider>{children}</SavingProvider>
        </DatasetProvider>
      </RenderingsProvider>
    </ProcessorProvider>
  </DisputeProvider>
)
