import React, {
  createContext,
  useState,
  useContext,
  useMemo,
  useEffect
} from 'react'
import { useParams } from 'react-router-dom'
import { DisputeDTO } from '../dtos'
import { useDispute } from '../useDet'

interface IDisputeContext {
  disputeId?: string
  isDisputeLoading: boolean
  setDispute: (dispute: DisputeDTO) => void
  dispute: DisputeDTO
}

const DisputeContext = createContext<IDisputeContext>({})

export const DisputeProvider = ({ children }) => {
  const { id: disputeId } = useParams<{ id: string }>()
  const { disputeData, isDisputeLoading } = useDispute(disputeId)
  const [dispute, setDispute] = useState(null)

  useEffect(() => {
    setDispute(disputeData)
  }, [setDispute, disputeData])

  const contextValue = useMemo(
    () => ({
      disputeId,
      dispute,
      setDispute,
      isDisputeLoading
    }),
    [disputeId, dispute, setDispute, isDisputeLoading]
  )

  return (
    <DisputeContext.Provider value={contextValue}>
      {children}
    </DisputeContext.Provider>
  )
}

export const useDisputeContext = () => {
  return useContext(DisputeContext)
}
