import {
  DatasetDTO,
  DisputeDTO,
  ExtendedRendering,
  ImageDTO,
  ProcessorRequirementsDTO,
  RenderingDTO,
  TemplateType
} from '../pages/DET-edit/dtos'
import {
  DataFieldType,
  Filters,
  RenderingStatus
} from '../pages/DET-edit/enums'
import axios from './http/axios-local'
import { ISaveResult } from '../pages/DET-edit/interfaces'

export const getDatasetPath = (path?: string) => {
  if (!path) {
    return
  }
  return path.slice(2)
}

export const prepareChanges = (
  changes: Record<string, string | ImageDTO>
): { changes: any[]; newFiles: any[]; removedFiles: any[] } => {
  if (!changes) {
    return { changes: [], newFiles: [], removedFiles: [] }
  }

  return Object.keys(changes).reduce(
    (hash, key) => {
      const value = changes[key]
      if (value?.type === DataFieldType.IMAGE && value.image) {
        hash.newFiles.push({ path: key, value })
      } else if (value?.type === DataFieldType.IMAGE) {
        hash.removedFiles.push({ path: key, value })
      } else {
        hash.changes.push({ path: key, value })
      }
      return hash
    },
    { changes: [], newFiles: [], removedFiles: [] }
  )
}

export const saveTextChanges = async (
  disputeId: string,
  changes: { path: string; value: string }[]
) => {
  if (changes.length) {
    try {
      const preparedChanges = {
        edits: changes
      }
      const { data } = await axios.put<{
        dataset: DatasetDTO
        // eslint-disable-next-line camelcase
        dirty_renderings_ids: string[]
        // eslint-disable-next-line camelcase
        processor_requirements: ProcessorRequirementsDTO
      }>(`/det/datasets/${disputeId}`, preparedChanges)

      return {
        textDataset: data?.dataset,
        textDirtyRenderingsIds: data?.dirty_renderings_ids,
        textProcessorRequirements: data?.processor_requirements,
        error: null
      }
    } catch (error) {
      return {
        textDataset: null,
        textDirtyRenderingsIds: null,
        textProcessorRequirements: null,
        error
      }
    }
  }

  return {
    textDataset: null,
    textDirtyRenderingsIds: null,
    error: null
  }
}

export const uploadImages = async (
  disputeId: string,
  images: { path: string; value: ImageDTO }[]
) => {
  if (images.length) {
    try {
      const promises = images.map(({ path, value }) => {
        const formData = new FormData()
        formData.append('id', disputeId)
        formData.append('template_id', `${value.templateId}`)
        formData.append('path', path)
        formData.append('evidence[name]', value.name)
        formData.append('evidence[group]', value.group)
        formData.append('evidence[include_in_document]', 'true')
        formData.append('evidence[evidence]', value.image)

        if (value.caption) {
          formData.append('evidence[caption]', value.caption)
        }

        return axios.post(`/det/evidences/upload/`, formData)
      })

      const response = await Promise.all(promises)

      const savedChanges = response.reduce(
        (hash, data) => {
          if (data?.data?.dirty_renderings_ids?.length) {
            hash.dirtyRenderingIds.push(...data.data.dirty_renderings_ids)
          }

          if (data?.data?.dataset) {
            hash.dataset = {
              ...hash.dataset,
              ...data?.data?.dataset
            }
          }

          if (data.data.processor_requirements) {
            hash.processorRequirements = {
              ...hash.processorRequirements,
              ...data?.data?.processorRequirements
            }
          }

          return hash
        },
        { dataset: {}, dirtyRenderingIds: [], processorRequirements: {} }
      )

      return {
        success: true,
        error: null,
        uploadDirtyRenderingsIds: Array.from(
          new Set(savedChanges.dirtyRenderingIds)
        ),
        uploadDataset: savedChanges.dataset,
        processorRequirements: savedChanges.processorRequirements
      }
    } catch (error) {
      return {
        success: false,
        error,
        uploadDirtyRenderingsIds: null,
        uploadDataset: null,
        processorRequirements: null
      }
    }
  }

  return {
    success: null,
    error: null,
    uploadDirtyRenderingsIds: null,
    uploadDataset: null
  }
}

export const deleteImages = async (
  disputeId: string,
  images: { path: string; value: ImageDTO }[]
) => {
  if (images.length) {
    try {
      const promises = images.map(({ path, value }) => {
        const body = {
          id: disputeId,
          template_id: value.templateId,
          path
        }
        return axios.delete(`/det/evidences`, { data: body })
      })

      const response = await Promise.all(promises)

      const savedChanges = response.reduce(
        (hash, data) => {
          if (data?.data?.dirty_renderings_ids?.length) {
            hash.dirtyRenderingIds.push(...data.data.dirty_renderings_ids)
          }

          if (data?.data?.dataset) {
            hash.dataset = {
              ...hash.dataset,
              ...data?.data?.dataset
            }
          }

          if (data?.data?.processor_requirements) {
            hash.processorRequirements = {
              ...hash.processorRequirements,
              ...data?.data?.processor_requirements
            }
          }

          return hash
        },
        { dataset: {}, dirtyRenderingIds: [], processorRequirements: {} }
      )

      return {
        success: true,
        removeDirtyRenderingsIds: Array.from(
          new Set(savedChanges.dirtyRenderingIds)
        ),
        removeDataset: savedChanges.dataset,
        removeProcessorRequirements: savedChanges.processorRequirements,
        error: null
      }
    } catch (error) {
      return {
        success: false,
        removeDirtyRenderingsIds: null,
        removeDataset: null,
        removeProcessorRequirements: null,
        error
      }
    }
  }

  return {
    success: null,
    removeDirtyRenderingsIds: null,
    removeDataset: null,
    error: null
  }
}

export const saveAllChanges = async (
  disputeId: string,
  changes: Record<string, string | ImageDTO>
): Promise<ISaveResult> => {
  const allChanges = prepareChanges(changes)

  const newImages = allChanges.newFiles as {
    path: string
    value: ImageDTO
  }[]

  const {
    error: addImageError,
    uploadDirtyRenderingsIds,
    uploadDataset,
    processorRequirements
  } = await uploadImages(disputeId, newImages)

  if (addImageError) {
    console.log('Error while saving images', addImageError)
  }

  const removedImages = allChanges.removedFiles as {
    path: string
    value: ImageDTO
  }[]

  const {
    error: removeImageError,
    removeDirtyRenderingsIds,
    removeDataset,
    removeProcessorRequirements
  } = await deleteImages(disputeId, removedImages)

  if (removeImageError) {
    console.log('Error while saving images', removeImageError)
  }

  const textChanges = allChanges.changes

  const cleanedTextChanges = textChanges?.map(change => {
    // Cleaning up fe-only needed id from array changes
    if (Array.isArray(change?.value)) {
      return {
        ...change,
        value: change?.value?.map(value => {
          const cleanedValue = { ...value }
          delete cleanedValue.det_internal_id
          return cleanedValue
        })
      }
    }

    return change
  })

  const {
    textDataset,
    textDirtyRenderingsIds,
    textProcessorRequirements,
    error
  } = await saveTextChanges(disputeId, cleanedTextChanges)

  if (error) {
    console.log('Error while saving text edits', error)
  }

  const latestDataset = textDataset || removeDataset || uploadDataset
  const latestProcessorRequirements =
    textProcessorRequirements ||
    removeProcessorRequirements ||
    processorRequirements
  const latestDirtyRenderingIds =
    textDirtyRenderingsIds ||
    removeDirtyRenderingsIds ||
    uploadDirtyRenderingsIds ||
    []

  return {
    dataset: latestDataset,
    dirtyRenderingIds: latestDirtyRenderingIds,
    processorRequirements: latestProcessorRequirements,
    addImageError,
    removeImageError,
    error
  }
}

const dfsByStatus = (
  parentRenderings: ExtendedRendering[],
  targetStatus: RenderingStatus,
  renderingsHashData: Record<string, RenderingDTO>,
  parentTemplateId?: number
): ExtendedRendering[] => {
  let parentTemplateIdToPass = parentTemplateId
  const result: ExtendedRendering[] = []

  for (const rendering of parentRenderings) {
    if (rendering.status === targetStatus) {
      // If current level has needed status - it can be a parent of the
      // next level. If it doesn't have needed status, then the previous
      // level should be new parent
      parentTemplateIdToPass = rendering.templateId

      const updatedRendering = { ...rendering }

      if (!updatedRendering.filteredParentTemplateIds) {
        updatedRendering.filteredParentTemplateIds = []
      }

      if (parentTemplateId) {
        updatedRendering.filteredParentTemplateIds.push(parentTemplateId)
      }

      result.push(updatedRendering)
    }

    if (!rendering.childTemplateIds?.length) {
      parentTemplateIdToPass = parentTemplateId
      continue
    }

    const nextParentRenderings = rendering.childTemplateIds.map(templateId => ({
      ...renderingsHashData[templateId]
    }))

    const innerResult = dfsByStatus(
      nextParentRenderings,
      targetStatus,
      renderingsHashData,
      parentTemplateIdToPass
    )

    const lastPreviousResult = result[result.length - 1]
    if (lastPreviousResult && !lastPreviousResult.filteredChildTemplateIds) {
      lastPreviousResult.filteredChildTemplateIds = []
    }

    if (innerResult.length && lastPreviousResult && parentTemplateIdToPass) {
      innerResult.forEach(innerRendering => {
        lastPreviousResult.filteredChildTemplateIds.push(
          innerRendering.templateId
        )
      })
    }

    if (innerResult) {
      result.push(...innerResult)
    }
  }

  return result
}

export const getNestedParentRenderingByFilter = (
  selectedFilter: Filters,
  renderingsData: RenderingDTO[],
  renderingsHashData: Record<string, RenderingDTO>
) => {
  let targetStatus
  if (selectedFilter === Filters.Incomplete) {
    targetStatus = RenderingStatus.INCOMPLETE
  } else if (selectedFilter === Filters.Enhanceable) {
    targetStatus = RenderingStatus.ENHANCEABLE
  } else {
    return []
  }

  const parentRenderings = renderingsData.filter(
    rendering => rendering.parentTemplateIds?.length === 0
  )

  const result = dfsByStatus(parentRenderings, targetStatus, renderingsHashData)
  const parentNodeResults = result.filter(
    rendering => !rendering.filteredParentTemplateIds?.length
  )

  return parentNodeResults
}

export const getRenderingsToDisplay = (
  selectedFilter: Filters,
  renderingsData: RenderingDTO[],
  renderingsHashData: Record<string, RenderingDTO>,
  lockedStatus: { locked?: boolean; expired?: boolean }
) => {
  const configurationRenderings = renderingsData.filter(
    rendering => rendering.templateType === TemplateType.configurationPanel
  )

  if (lockedStatus?.locked || lockedStatus?.expired) {
    return configurationRenderings
  }

  const responseRenderings = renderingsData.filter(
    rendering => rendering.templateType === TemplateType.responseTemplate
  )

  let displayRenderings = []
  if (selectedFilter === Filters.AllFields) {
    // It should return only the most parent-level renderings.
    // Child renderings will be handled by the parent ones itself.

    displayRenderings = responseRenderings.filter(
      rendering => rendering.parentTemplateIds?.length === 0
    )
  } else {
    displayRenderings = getNestedParentRenderingByFilter(
      selectedFilter,
      responseRenderings,
      renderingsHashData
    )
  }

  return [...configurationRenderings, ...displayRenderings]
}

export const getRenderingsByTypeHash = (renderings: RenderingDTO[]) => {
  return renderings?.reduce(
    (hash, rendering) => {
      hash[rendering.templateType].push(rendering)
      return hash
    },
    {
      [TemplateType.stylesheet]: [],
      [TemplateType.configurationPanel]: [],
      [TemplateType.responseTemplate]: []
    }
  )
}

export const getRenderingsToRender = (
  renderingsByType: Record<TemplateType, RenderingDTO[]>,
  dispute: DisputeDTO
) => {
  if (dispute?.locked || dispute?.expired) {
    return renderingsByType[TemplateType.configurationPanel]
  } else {
    return [
      ...renderingsByType[TemplateType.configurationPanel],
      ...renderingsByType[TemplateType.responseTemplate]
    ]
  }
}

// divide renderings into different groups to set in state
export const getRenderingsToSet = (
  dispute: DisputeDTO,
  renderings: RenderingDTO[]
) => {
  const renderingsByType = getRenderingsByTypeHash(renderings)
  const renderingsToDisplay = getRenderingsToRender(renderingsByType, dispute)

  const renderingsHash = renderingsToDisplay.reduce((hash, item) => {
    hash[item.templateId] = item
    return hash
  }, {})

  return {
    renderingsToDisplay,
    stylesheetRenderings: renderingsByType[TemplateType.stylesheet],
    renderingsHash
  }
}
