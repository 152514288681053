import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import LinearProgress from '@material-ui/core/LinearProgress'
import useBindTableHeightToResize from './use-bind-table-height-to-resize'
import axios from '../../utils/http/axios-local'

const Looker = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [url, setUrl] = useState('')
  const classes = useStyles()
  const { report } = useParams()
  const frameHeight = useBindTableHeightToResize()

  useEffect(() => {
    axios.get('/looker/merchant-reports').then(response => {
      const data = response?.data
      const item = data.find(element => element.name === report)
      axios
        .get(`/looker/get-url?embed_url=${item.embeddedUrl}`)
        .then(response => {
          setUrl(response.data)
        })
        .catch(e => console.warn(e))
    })
  }, [])

  const onIframeLoad = () => {
    setIsLoading(false)
  }

  return (
    <>
      {isLoading && (
        <div
          className={classes.loaderWrapper}
          style={{ height: frameHeight - 40 }}
        >
          <LinearProgress className={classes.loader} />
        </div>
      )}
      {url && (
        <iframe
          src={url}
          className={classes.iframe}
          style={{
            height: frameHeight - 40,
            display: isLoading ? 'none' : 'block'
          }}
          onLoad={onIframeLoad}
        ></iframe>
      )}
    </>
  )
}

const useStyles = makeStyles(theme => ({
  filterContainer: {
    display: 'flex'
  },
  select: {
    width: '100%'
  },
  iframe: {
    border: 'none',
    padding: 0,
    width: '100%'
  },
  loaderWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  loader: {
    width: '80%'
  }
}))

export default Looker
