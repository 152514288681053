import React from 'react'
import { useCurrentUser } from '../../contexts/CurrentUserContext'
import { makeStyles } from '@material-ui/core/styles'
import { AppBar, Toolbar } from '@material-ui/core'
import Search from './search'
import MainNav from './main-nav'
import UserInfo from './user-info'
import UserMenu from './user-menu'
import AdminMenu from './admin-menu'
import DeveloperMenu from './developer-menu'
import LoginNav from '../../common/header/login-nav'
import MenuLogo from './menu-logo'

const Header = () => {
  const classes = useStyles()
  const { currentUser } = useCurrentUser()

  const renderUserHeader = () => (
    <AppBar position="fixed" className={classes.root} id="header">
      <Toolbar classes={{ root: classes.toolbar }}>
        <div className={classes.navContainer}>
          <MainNav MenuLogo={MenuLogo} />
          <Search />
        </div>
        <div className={classes.menuContainer}>
          <UserInfo />
          <UserMenu />
          {currentUser.chargebackEmployee && <AdminMenu />}
          {currentUser.admin && <DeveloperMenu />}
        </div>
      </Toolbar>
    </AppBar>
  )

  const renderLoginHeader = () => (
    <AppBar position="fixed" className={classes.root} id="header">
      <Toolbar classes={{ root: classes.toolbar }}>
        <div className={classes.navContainer}>
          <MenuLogo />
        </div>
        <div className={classes.menuContainer}>
          <LoginNav />
        </div>
      </Toolbar>
    </AppBar>
  )

  return currentUser.isLoggedIn() ? renderUserHeader() : renderLoginHeader()
}

const useStyles = makeStyles(theme => ({
  root: {
    top: 0,
    backgroundColor: theme.palette.primary,
    zIndex: 1400
  },
  toolbar: {
    justifyContent: 'space-between'
  },
  navContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  menuContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '8px'
  },
  userContainer: {
    padding: 12
  }
}))

export default Header
