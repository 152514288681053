import React, { useState } from 'react'
import { Disposition, DisputeDTO } from '../dtos'
import { Select } from '@siftscience/focus-components/select'
import { Text } from '@siftscience/focus-components/text'
import { capitalize } from 'lodash'
import { useDisputeContext } from '../context/dispute-context'
import ConfirmationModal, {
  ConfirmationModalProps
} from '../confirmation-modal'
import axios from '../../../utils/http/axios-local'

const DISPOSITION_OPTIONS = [
  Disposition.won,
  Disposition.lost,
  Disposition.pending
]

const CONFIRMATION_TITLE = 'Are you sure?'

const generateConfirmationText = (currentDisposition, newDisposition) => {
  return (
    <Text>
      This dispute has already been marked <b>{currentDisposition}</b> but you
      are changing the disposition to <b>{newDisposition}</b>. Please note that
      re-opening a claim, for example if a pre-arbitration is opened, should not
      change the win/loss status of a previous stage of the dispute. Are you
      sure you want to proceed?
    </Text>
  )
}

const DispositionSelector = () => {
  const { dispute, disputeId, setDispute } = useDisputeContext()
  const [confirmationModalState, setConfirmationModalState] =
    useState<ConfirmationModalProps>(null)

  const changeDisposition = (newDisposition: Disposition) => {
    axios
      .patch<DisputeDTO>(`/det/disputes/${disputeId}`, {
        disposition: newDisposition
      })
      .then(({ data }) => {
        if (data) {
          setDispute({ ...data })
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  const onConfirm = (newDisposition: Disposition) => () => {
    changeDisposition(newDisposition)
    setConfirmationModalState(null)
  }

  const onCancel = () => {
    setConfirmationModalState(null)
  }

  const onDispositionChange = (disposition: Disposition) => {
    if (dispute.disposition === Disposition.pending) {
      changeDisposition(disposition)
      return
    }

    const text = generateConfirmationText(dispute?.disposition, disposition)
    setConfirmationModalState({
      title: CONFIRMATION_TITLE,
      text,
      onConfirm: onConfirm(disposition),
      onCancel
    })
  }

  return (
    <>
      <Select
        items={DISPOSITION_OPTIONS}
        getItemLabel={(item: string) => capitalize(item)}
        selectedItem={dispute?.disposition}
        onChange={onDispositionChange}
        placeholder="Win / Loss"
        variant="secondary"
        strategy="fixed"
        stretch={false}
      />
      <ConfirmationModal
        {...(confirmationModalState || {})}
        isOpen={!!confirmationModalState}
      />
    </>
  )
}

export default DispositionSelector
